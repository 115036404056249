import Cookies from 'js-cookie'
import LazyLoad from 'vanilla-lazyload'

export const getMainVideo = () => {
  return document.querySelector('.video--major video') as HTMLVideoElement | undefined
}

const pauseVideo = (video: HTMLVideoElement, button: HTMLElement | null = null, setCookieParameter = true) => {
  video.pause()
  // to compliment lazy loading
  video.removeAttribute('autoplay')

  if (setCookieParameter) {
    Cookies.set('video-main-paused', 'true')
  }

  if (button) {
    button.setAttribute('data-state', 'pause')
    button.innerHTML = 'Пауза'
  }
}

const unPauseVideo = (video: HTMLVideoElement, button: HTMLElement | null = null, setCookieParameter = true) => {
  video.play()
  if (setCookieParameter) {
    Cookies.set('video-main-paused', 'false')
  }

  if (button) {
    button.setAttribute('data-state', 'play')
    button.innerHTML = 'Проигрывается'
  }
}

export const initPausableVideo = () => {
  const allVideos = document.querySelectorAll<HTMLElement>('figure.video')

  if (allVideos.length === 0) {
    return
  }

  void new LazyLoad({})

  for (const videoWrap of allVideos) {
    const video = videoWrap.querySelector<HTMLVideoElement>('video')
    const videoPlayPauseButton = videoWrap.querySelector<HTMLElement>('.video__playpause')

    if (!video) {
      continue
    }

    const majorVideo = videoWrap.classList.contains('video--major')

    if (majorVideo && videoPlayPauseButton && (Cookies.get('video-main-paused') === 'true' || import.meta.env.DEV)) {
      pauseVideo(video, videoPlayPauseButton, false)
    }

    videoWrap?.addEventListener(
      'click',
      function () {
        if (video.paused) {
          unPauseVideo(video, videoPlayPauseButton, majorVideo)
        } else {
          pauseVideo(video, videoPlayPauseButton, majorVideo)
        }
      },
      { passive: true },
    )
  }
}

export const changeMainVideoState = (setCookieParameter = true) => {
  const video = getMainVideo()

  if (video) {
    if (video.paused) {
      unPauseVideo(video, undefined, setCookieParameter)
    } else {
      pauseVideo(video, undefined, setCookieParameter)
    }
  }
}
