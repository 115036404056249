<script lang="ts">
  import {
    disableHeaderSearchArrowNav,
    initHeaderSearchArrowNav,
  } from '@js/components/HeaderSearch/headerSearchArrowNav.ts'
  import HeaderSearchInput from '@js/components/HeaderSearch/HeaderSearchInput.svelte'
  import { notiStore } from '@js/components/Notifications/notification-store'
  import { mobile } from '@js/stores/globalStores'
  import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock-upgrade'
  import { onMount, tick } from 'svelte'
  import type { FocusEventHandler } from 'svelte/elements'

  let resultHTML = ''
  export let searchContainer: HTMLElement
  let searchWrap: HTMLElement | null
  let resultsModalWidth = '50vw'
  let show = false

  let backdrop: HTMLElement | undefined

  const mobileQuery = window.matchMedia('screen and (min-width: 1201px)')

  const resizeObserver = new ResizeObserver((entries) => {
    if (mobileQuery.matches) {
      const innerWidth = entries[0].contentRect.width
      // 150 - ширина левой колонки
      resultsModalWidth = innerWidth + 150 + 'px'
    } else {
      const clientRects = entries[0].target.getClientRects()
      if (clientRects.length > 0) {
        resultsModalWidth = Math.floor(clientRects[0].right) - 20 + 'px'
      }
    }
  })

  const onFocusOut = () => {
    if (
      searchWrap &&
      searchContainer &&
      !(searchWrap.matches(':focus-within') || searchContainer.matches(':focus-within'))
    ) {
      show = false
    }
  }

  onMount(() => {
    backdrop = document.createElement('div')
    backdrop.classList.add('modal-backdrop', 'fade', 'modal-backdrop--search')
    document.querySelector('.page-header__bottom')?.append(backdrop)
    backdrop.addEventListener('click', () => {
      show = false
    })

    window.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        show = false
      }
    })

    if (searchWrap) {
      searchWrap.addEventListener('focusout', onFocusOut, { capture: true, passive: true })
    }

    if (searchContainer) {
      searchContainer.addEventListener('focusout', onFocusOut, { capture: true, passive: true })
    }

    return () => {
      backdrop?.remove()
      disableHeaderSearchArrowNav(searchContainer)
    }
  })

  const onInputUpdate = async (event: CustomEvent<string>) => {
    resultHTML = event.detail
    await tick()
    initHeaderSearchArrowNav(searchContainer)
  }

  const onError = () => {
    notiStore.push('error', {
      props: { content: 'Не удалось получить результаты поиска' },
      timeout: false,
    })
  }

  const onInputMount = (event: CustomEvent<HTMLElement | null>) => {
    searchWrap = event.detail

    if (searchWrap) {
      resizeObserver.observe(searchWrap)
    } else {
      resizeObserver.disconnect()
    }
  }

  // Закрываем поиск при изменении статуса мобильности
  mobile.subscribe(() => {
    show = false
  })

  const onShowHide = async () => {
    if (show) {
      searchContainer.classList.add('active')
      backdrop?.classList.add('show')
      if (searchWrap) {
        disableBodyScroll(searchWrap)
      }

      await tick()
      initHeaderSearchArrowNav(searchContainer)
    } else {
      searchContainer.classList.remove('active')
      backdrop?.classList.remove('show')
      if (searchWrap) {
        enableBodyScroll(searchWrap)
      }

      disableHeaderSearchArrowNav(searchContainer)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-expressions,no-sequences
  $: show, onShowHide()
</script>

<HeaderSearchInput
  ariaControls="search-results-modal"
  bind:show
  on:error={onError}
  on:mount={onInputMount}
  on:update={onInputUpdate}
/>
<div
  aria-live="polite"
  class="search-results-modal search-results-modal--desktop fade"
  class:show
  id="search-results-modal"
  style:width={resultsModalWidth}
>
  {#if show}
    {@html resultHTML}
  {/if}
</div>
