// https://stackoverflow.com/questions/13382516/getting-scroll-bar-width-using-javascript
// eslint-disable-next-line canonical/filename-match-exported
const getScrollbarWidth = (): number => {
  // Creating invisible container
  const outer = document.createElement('div')
  outer.style.visibility = 'hidden'
  outer.style.overflow = 'scroll' // forcing scrollbar to appear
  // outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer)

  // Creating inner element and placing it in the container
  const inner = document.createElement('div')
  outer.appendChild(inner)

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth

  // Removing temporary elements from the DOM
  outer.parentNode?.removeChild(outer)

  return scrollbarWidth
}

const setScrollbarWidth = () => {
  const previousWidth = window.getComputedStyle(document.documentElement).getPropertyValue('--scrollbar-width')
  const newWidth = `${getScrollbarWidth()}px`

  if (newWidth !== previousWidth) {
    document.documentElement.style.setProperty('--scrollbar-width', newWidth)
  }
}

const init = () => {
  window.addEventListener('load', setScrollbarWidth)
  window.addEventListener('resize', setScrollbarWidth)

  setScrollbarWidth()
}

export default init
