// based on https://css-tricks.com/simple-swipe-with-vanilla-javascript/

const unify = (event: MouseEvent | TouchEvent) => {
  return 'changedTouches' in event ? event.changedTouches[0] : event
}

export const touchHandler = (options: TouchHandlerOptions) => {
  const defaultOptions: Partial<TouchHandlerOptions> = {
    mode: 'add',
  }
  const finalOptions: TouchHandlerOptions = {
    ...defaultOptions,
    ...options,
  }

  let locked = false
  let x0: number | null = null
  let y0: number | null = null
  let dragX: number | null = null
  let dragY: number | null = null

  const lock = (event: MouseEvent | TouchEvent) => {
    x0 = unify(event).clientX
    y0 = unify(event).clientY
    locked = true

    if (finalOptions.startHandler) {
      finalOptions.startHandler(0, 0, event)
    }
  }

  const drag = (event: MouseEvent | TouchEvent) => {
    // event.preventDefault()

    if (locked && x0 && y0) {
      dragX = unify(event).clientX - x0
      dragY = unify(event).clientY - y0

      if (finalOptions.dragHandler) {
        finalOptions.dragHandler(dragX, dragY, event)
      }
    }
  }

  const move = (event: MouseEvent | TouchEvent) => {
    if (locked && x0 && y0) {
      dragX = unify(event).clientX - x0
      dragY = unify(event).clientY - y0
      x0 = null
      y0 = null
      locked = false

      if (finalOptions.endHandler) {
        finalOptions.endHandler(dragX, dragY, event)
      }
    }
  }

  // С passive не работает preventDefault, но это хорошо для производительности
  const eventOptions: AddEventListenerOptions = {
    capture: false,
    passive: true,
  }

  if (finalOptions.mode === 'add') {
    finalOptions.element.addEventListener('mousedown', lock, eventOptions)
    finalOptions.element.addEventListener('touchstart', lock, eventOptions)

    if (finalOptions.dragHandler) {
      finalOptions.element.addEventListener('mousemove', drag, eventOptions)
      finalOptions.element.addEventListener('touchmove', drag, eventOptions)
    }

    finalOptions.element.addEventListener('mouseup', move, eventOptions)
    finalOptions.element.addEventListener('touchend', move, eventOptions)
  } else {
    finalOptions.element.removeEventListener('mousedown', lock, eventOptions)
    finalOptions.element.removeEventListener('touchstart', lock, eventOptions)

    finalOptions.element.removeEventListener('mousemove', drag, eventOptions)
    finalOptions.element.removeEventListener('touchmove', drag, eventOptions)

    finalOptions.element.removeEventListener('mouseup', move, eventOptions)
    finalOptions.element.removeEventListener('touchend', move, eventOptions)
  }
}

export const swipeToRightHelper = (targetElement: HTMLElement, endFunction: () => void, draggable = false) => {
  touchHandler({
    element: targetElement,
    startHandler: () => {
      if (draggable) {
        targetElement.classList.add('dragged')
      }
    },
    dragHandler: (dragX) => {
      if (draggable && dragX > 10) {
        targetElement.style.transform = `translateX(${dragX}px)`
      }
    },
    endHandler: (dragX) => {
      if (draggable) {
        targetElement.style.removeProperty('transform')
        targetElement.classList.remove('dragged')
      }

      if (dragX > 100) {
        endFunction()
      }
    },
  })
}

type TouchHandler = (dragX: number, dragY?: number, event?: MouseEvent | TouchEvent) => void

export type TouchHandlerOptions = {
  element: HTMLElement
  mode?: 'add' | 'remove'
  startHandler?: TouchHandler
  endHandler?: TouchHandler
  dragHandler?: TouchHandler
}
