import { sanSessionStorage } from '@js/includes/storageFactory.ts'

let productClicked = false

export const saveCardNeighbors = (card: HTMLElement) => {
  const cardParent = card.closest<HTMLElement>('.product-card-grid, .swiper-wrapper')

  if (!cardParent) {
    return
  }

  productClicked = true

  const neighborCards = cardParent.querySelectorAll<HTMLElement>('.product-card')
  const neighbors: string[] = []

  for (const neighbor of neighborCards) {
    if (neighbor.dataset.productId) {
      neighbors.push(neighbor.dataset.productId)
    }
  }

  sanSessionStorage.setItem('product-neighbors', JSON.stringify(neighbors))
}

export const clearCardNeighbors = () => {
  sanSessionStorage.removeItem('product-neighbors')
}

export const initCardNeighborsSaving = () => {
  document.addEventListener('beforeunload', () => {
    if (!productClicked) {
      clearCardNeighbors()
    }

    return true
  })

  document.addEventListener(
    'click',
    (event) => {
      if (!event.target) {
        return
      }

      const target = event.target as HTMLElement
      const closestLink = target.closest('a')

      if (closestLink) {
        const href = closestLink.getAttribute('href')
        if (href?.startsWith('/product/')) {
          // При прямом переходе между товарами сохраняем соседние товары
          productClicked = true

          const parentCard = target.closest<HTMLElement>('.product-card')
          if (parentCard) {
            saveCardNeighbors(parentCard)
          }
        } else {
          clearCardNeighbors()
        }
      }
    },
    { passive: true },
  )
}
