export const initRegisterForm = async (parent: HTMLElement, modal = true) => {
  const id = modal ? 'modal-register-form' : 'page-register-form'
  const modalRegisterWrap = parent.querySelector<HTMLElement>(`#${id}-wrap`)
  if (modalRegisterWrap) {
    const RegistrationImport = await import('@js/components/Forms/Registration.svelte')
    void new RegistrationImport.default({
      target: modalRegisterWrap,
      props: {
        id,
      },
    })
  }
}
