import { A11y, Autoplay, Navigation, Pagination } from 'swiper/modules'
import { type SwiperOptions } from 'swiper/types'

export const defaultSwiperSettings: SwiperOptions = {
  // configure Swiper to use modules
  modules: [Navigation, Pagination, A11y, Autoplay],
  lazyPreloadPrevNext: 1,
  loop: true,
  autoplay: {
    pauseOnMouseEnter: true,
  },
  // touchReleaseOnEdges: true,
  // touchStartForcePreventDefault: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  a11y: {
    firstSlideMessage: 'Это первый слайд',
    lastSlideMessage: 'Это последний слайд',
    nextSlideMessage: 'Следующий слайд',
    prevSlideMessage: 'Предыдущий слайд',
    paginationBulletMessage: 'Перейти к слайду {{index}}',
  },
}
