// eslint-disable-next-line unicorn/prevent-abbreviations, canonical/filename-match-regex
import { debounce } from '@js/includes/functions'

const updateButton = (button: HTMLElement) => {
  if (window.scrollY > window.innerHeight) {
    button.classList.add('show')
  } else {
    button.classList.remove('show')
  }
}

export const initToTopButton = () => {
  const toTopButton = document.querySelector<HTMLElement>('.btn--to-top')

  if (!toTopButton) {
    return
  }

  // Не показываем стрелку на мобильном Яндекс-браузере, у него есть своя
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (navigator.userAgent.includes('YaBrowser') && navigator.userAgentData?.mobile) {
    return
  }

  updateButton(toTopButton)

  window.addEventListener(
    'scroll',
    debounce(() => {
      updateButton(toTopButton)
    }),
  )

  window.addEventListener(
    'resize',
    debounce(() => {
      updateButton(toTopButton)
    }),
  )
}
