import { sanSessionStorage } from '@js/includes/storageFactory.ts'

export const saveLastVisitedCatalog = () => {
  // Если в каталоге, сохраняем последний просмотренный каталог, если не в каталоге и не в товаре, удаляем сохранение
  if (
    window.location.pathname.startsWith('/catalog/') ||
    (window.location.pathname.startsWith('/brand/') && document.querySelector('.page-catalog'))
  ) {
    sanSessionStorage.setItem('lastVisitedCatalog', window.location.href)
  } else if (
    !window.location.pathname.startsWith('/product/') &&
    !window.location.pathname.startsWith('/collection-tile/')
  ) {
    sanSessionStorage.removeItem('lastVisitedProduct')
  }
}

export const getLastVisitedCatalog = () => sanSessionStorage.getItem('lastVisitedCatalog')
