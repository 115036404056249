<script lang="ts">
  import { type NotificationInstance } from '@svelte-put/noti'
  import { createEventDispatcher, onMount } from 'svelte'

  export let notification: NotificationInstance

  const { progress } = notification

  export let content = 'Placeholder'

  const dispatch = createEventDispatcher<{ resolve: string }>()
  const dismiss = () => dispatch('resolve', 'popped from within component')

  let notificationElement: HTMLElement | undefined

  let mouseIn = false
  let focusIn = false

  const inHandler = (event: MouseEvent | FocusEvent) => {
    if (event.type === 'focusin') {
      focusIn = true
    } else {
      mouseIn = true
    }

    if (mouseIn || focusIn) {
      progress.pause()
    }
  }

  const outHandler = (event: MouseEvent | FocusEvent) => {
    if (event.type === 'focusout') {
      focusIn = false
    } else {
      mouseIn = false
    }

    if (!mouseIn && !focusIn) {
      progress.resume()
    }
  }

  onMount(() => {
    if (notificationElement) {
      notificationElement.addEventListener('mouseenter', inHandler)
      notificationElement.addEventListener('focusin', inHandler)
      notificationElement.addEventListener('mouseleave', outHandler)
      notificationElement.addEventListener('focusout', outHandler)
    }

    return () => {
      if (notificationElement) {
        notificationElement.removeEventListener('mouseenter', inHandler)
        notificationElement.removeEventListener('focusin', inHandler)
        notificationElement.removeEventListener('mouseleave', outHandler)
        notificationElement.removeEventListener('focusout', outHandler)
      }
    }
  })

  /*
    class:bg-success={notification.variant === 'success'}
    class:bg-danger={notification.variant === 'error'}
    */
</script>

<div
  class="toast"
  bind:this={notificationElement}
>
  <button
    type="button"
    class="btn-close btn-close-white float-end"
    data-bs-dismiss="toast"
    aria-label="Закрыть"
    on:click={dismiss}
  ></button>
  <div class="toast-body">
    {@html content}
  </div>
</div>
