// добавляем свайп для закрытия offcanvas

import { swipeToRightHelper, touchHandler } from '@js/includes/touchHandler.ts'
import { mobile } from '@js/stores/globalStores.ts'
import { Offcanvas } from 'bootstrap'

export const initOffcanvasNavbar = () => {
  const offcanvasNavbar = document.querySelector<HTMLElement>('#offcanvasNavbar')

  if (!offcanvasNavbar) {
    return
  }

  const offcanvasNavbarBootstrap = Offcanvas.getOrCreateInstance(offcanvasNavbar)

  mobile.subscribe((isMobile) => {
    if (isMobile) {
      swipeToRightHelper(
        offcanvasNavbar,
        () => {
          offcanvasNavbarBootstrap?.hide()
        },
        true,
      )
    } else {
      touchHandler({ element: offcanvasNavbar, mode: 'remove' })
    }
  })
}
