import Notification from './Notification.svelte'
import { store } from '@svelte-put/noti'

// define somewhere global, reuse across app
export const notiStore = store()
  // add a minimalistic variant config
  .variant('info', Notification)
  .variant('success', Notification)
  .variant('error', Notification)
  // build the actual NotificationStore
  .build()
