<script lang="ts">
  import { notiStore } from './notification-store.ts'
  import NotificationWrapper from '@svelte-put/noti/Notification.svelte'
  import { cubicOut } from 'svelte/easing'
  import { fade, fly } from 'svelte/transition'
</script>

<aside class="toast-container container position-fixed p-3">
  {#each $notiStore.notifications as notification (notification.id)}
    <div
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      class="toast-wrap container-fluid container-lg section--10"
      in:fly={{ duration: 250, y: -20, easing: cubicOut }}
      out:fade={{ duration: 250 }}
    >
      <NotificationWrapper {notification} />
    </div>
  {/each}
</aside>
