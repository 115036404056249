import { swipeToRightHelper, touchHandler } from '@js/includes/touchHandler.ts'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock-upgrade'

const hidePopup = (triggerButton: HTMLButtonElement, popupContent: HTMLDivElement, backdrop: HTMLElement) => {
  if (popupContent.classList.contains('show')) {
    popupContent.classList.remove('show')
    backdrop.classList.remove('show')
    triggerButton.setAttribute('aria-expanded', 'false')
    enableBodyScroll(popupContent)
  }
}

export const initSingleHeaderPopup = (popup: HTMLElement, additionalClickOutsideSelector?: string) => {
  const triggerButton = popup.querySelector<HTMLButtonElement>('.mobile-header-popup__button')
  const popupContent = popup.querySelector<HTMLDivElement>('.mobile-header-popup__content')

  if (!triggerButton || !popupContent) {
    return {
      destroy() {},
    }
  }

  const headerPopupBackdrop = document.createElement('div')
  headerPopupBackdrop.classList.add('modal-backdrop', 'modal-backdrop--header-popup', 'fade')
  document.body.append(headerPopupBackdrop)

  const hideHandler = () => {
    hidePopup(triggerButton, popupContent, headerPopupBackdrop)
  }

  const clickOutsideHandler = (event: MouseEvent) => {
    const target = event.target as HTMLElement

    // Если в списке исключений, завершаем функцию
    if (additionalClickOutsideSelector && target.closest(additionalClickOutsideSelector)) {
      return
    }

    if (!target.closest('.mobile-header-popup')) {
      hideHandler()
    }
  }

  triggerButton.addEventListener('click', () => {
    popupContent.classList.toggle('show')

    if (popupContent.classList.contains('show')) {
      headerPopupBackdrop.classList.add('show')
      disableBodyScroll(popupContent)

      triggerButton.setAttribute('aria-expanded', 'true')
    } else {
      triggerButton.setAttribute('aria-expanded', 'false')
      hideHandler()
    }
  })

  document.addEventListener('show.bs.offcanvas', hideHandler)

  document.addEventListener('show.bs.modal', hideHandler)

  const headerBottom = popup.closest('.page-header__bottom')
  headerBottom?.addEventListener('hidden', hideHandler)

  popup.addEventListener('hide', hideHandler)

  // Закрывать при свайпе
  swipeToRightHelper(popupContent, hideHandler)

  // Закрывать при клике снаружи
  document.addEventListener('click', clickOutsideHandler)

  return {
    destroy() {
      document.removeEventListener('click', clickOutsideHandler)
      headerBottom?.removeEventListener('hidden', hideHandler)
      headerPopupBackdrop.remove()
      enableBodyScroll(popupContent)

      document.removeEventListener('show.bs.offcanvas', hideHandler)

      document.removeEventListener('show.bs.modal', hideHandler)

      touchHandler({
        element: popupContent,
        mode: 'remove',
      })
    },
  }
}

export const initMobileHeaderPopups = () => {
  const allPopups = document.querySelectorAll<HTMLElement>('.mobile-header-popup')

  for (const popup of allPopups) {
    initSingleHeaderPopup(popup)
  }
}
