import { sanPreload } from '@js/includes/functions.ts'

export const initCitySelect = () => {
  const modal = document.querySelector<HTMLElement>('.city-select')

  if (modal) {
    const desktopButton = document.querySelector<HTMLElement>('.location-orderStatus__location-button')
    if (desktopButton) {
      sanPreload(desktopButton, async () => {
        const { initDesktopCitySelect } = await import('@js/components/CitySelect/loadCitySelect.ts')
        initDesktopCitySelect(modal)
      })
    }

    const mobileOffcanvas = document.querySelector<HTMLElement>('#offcanvasNavbar')
    mobileOffcanvas?.addEventListener(
      'show.bs.offcanvas',
      async () => {
        const { initMobileCitySelect } = await import('@js/components/CitySelect/loadCitySelect.ts')
        initMobileCitySelect(modal)
      },
      {
        passive: true,
        once: true,
      },
    )
  }
}
