import HeaderSearch from '@js/components/HeaderSearch/HeaderSearch.svelte'

export const initHeaderSearch = () => {
  const searchContainer = document.querySelector<HTMLElement>('.page-header__search')

  if (searchContainer) {
    searchContainer.innerHTML = ''

    void new HeaderSearch({
      target: searchContainer,
      props: {
        searchContainer,
      },
    })
  }
}
