import { makeCartRequest } from '@js/api/cart/makeCartRequest.ts'
import { sendEcommerceDataOnAddToCart } from '@js/includes/metrika.ts'
import { cartPurchaseIDs } from '@js/stores/cartData.ts'
import { get } from 'svelte/store'

const defaultSettings = {
  amount: '1',
  variantAmount: '1',
  preventDuplicates: false,
  optionIDs: new Set<string>(),
} satisfies Partial<AddToCartSettings>

// true: товар добавлен, false: товар не добавлен
export const addToCart = async (settings: AddToCartSettings) => {
  const finalSettings: AddToCartFinalSettings = { ...defaultSettings, ...settings }

  if (finalSettings.preventDuplicates) {
    const purchaseIDs = get(cartPurchaseIDs)
    if (purchaseIDs.has(finalSettings.variant)) {
      return false
    }
  }

  const parameters: AddToCartAPIParameters = {
    action: 'add_variant',
    variant_id: finalSettings.variant,
    amount: String(finalSettings.amount),
    var_amount: String(finalSettings.variantAmount),
  }

  const options = Array.from(finalSettings.optionIDs.values()).join(',')
  if (options) {
    parameters.dop_products = options
  }

  await makeCartRequest(parameters, 'Не удалось добавить товар в корзину')
  sendEcommerceDataOnAddToCart(finalSettings.variant, finalSettings.amount)
  return true
}

export const addToCartMultiple = async (variants: string | string[]) => {
  const finalVariantsList = typeof variants === 'string' ? variants : variants.join(',')

  const parameters: AddToCartAPIParameters = {
    action: 'add_variant_few',
    variant_id: finalVariantsList,
    amount: '1',
    var_amount: '1',
  }

  return await makeCartRequest(parameters, 'Не удалось добавить товары в корзину')
  // TODO: добавить метрику
  // sendEcommerceDataOnAddToCart(finalSettings.variant, finalSettings.amount)
}

export type AddToCartSettings = {
  // Несколько товаров разделяются запятыми
  variant: string
  amount?: string | number
  variantAmount?: string | number
  preventDuplicates?: boolean
  optionIDs?: Set<string>
}

export type AddToCartFinalSettings = Required<AddToCartSettings>

export type AddToCartAPIParameters = {
  action: 'add_variant' | 'add_variant_few'
  // Несколько товаров разделяются запятыми
  variant_id: string
  amount: string
  var_amount?: string
  dop_products?: string
}
