export const sanOnScroll = (options: OnScrollOptions) => {
  let ticking = false
  let timeoutRunning = false

  const scrollEventListener = () => {
    if (typeof options.functionAlways === 'function') {
      options.functionAlways()
    }

    // Если таймер активен, то ничего не делаем
    if (typeof options.functionOnTimer === 'function' && !timeoutRunning) {
      timeoutRunning = true
      setTimeout(() => {
        timeoutRunning = false
        if (options.functionOnTimer) {
          options.functionOnTimer()
        }
      }, options.timer ?? 60)
    }

    if (!ticking) {
      window.requestAnimationFrame(() => {
        if (typeof options.functionAnimation === 'function') {
          options.functionAnimation()
        }

        ticking = false
      })
    }

    ticking = true
  }

  window.addEventListener('scroll', scrollEventListener)

  options.abortSignal?.addEventListener('abort', () => {
    window.removeEventListener('scroll', scrollEventListener)
  })
}

export type OnScrollOptions = {
  functionAlways?: () => void
  functionAnimation?: () => void
  functionOnTimer?: () => void
  timer?: number
  abortSignal?: AbortSignal
}
