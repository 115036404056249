export const initVidstack = async (parent: HTMLElement | Document = document) => {
  const players = parent.querySelectorAll('media-player')

  if (players.length > 0) {
    const { RUSSIAN } = await import('./Vidstack.ts')

    for (const player of players) {
      const layout = player.querySelector('media-video-layout')
      if (layout) {
        layout.translations = RUSSIAN
      }
    }
  }
}
