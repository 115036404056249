import { destr } from 'destr'
import Cookies from 'js-cookie'
import { writable } from 'svelte/store'

export const userData = writable<UserData>(
  {
    id: window.santehnica_data?.user?.id ?? '',
    name: window.santehnica_data?.user?.name ?? Cookies.get('namka') ?? '',
    email: window.santehnica_data?.user?.email ?? Cookies.get('emailka') ?? '',
    phone: window.santehnica_data?.user?.phone ?? Cookies.get('phonka') ?? '',
    phone2: window.santehnica_data?.user?.phone2 ?? Cookies.get('phonka2') ?? '',
    address: window.santehnica_data?.user?.address ?? Cookies.get('addresska') ?? '',
  },
  (set) => {
    const cookieString = Cookies.get('userData')
    let cookieData: UserDataCookie | undefined
    if (cookieString) {
      cookieData = destr<UserDataCookie>(cookieString)
    }

    set({
      id: window.santehnica_data?.user?.id ?? '',
      name:
        window.santehnica_data?.user?.name && window.santehnica_data.user.name !== ''
          ? window.santehnica_data.user.name
          : cookieData?.name ?? '',
      email:
        window.santehnica_data?.user?.email && window.santehnica_data.user.email !== ''
          ? window.santehnica_data.user.email
          : cookieData?.email ?? '',
      phone:
        window.santehnica_data?.user?.phone && window.santehnica_data.user.phone !== ''
          ? window.santehnica_data.user.phone
          : cookieData?.phone ?? '',
      phone2:
        window.santehnica_data?.user?.phone2 && window.santehnica_data.user.phone2 !== ''
          ? window.santehnica_data.user.phone2
          : cookieData?.phone2 ?? '',
      address:
        window.santehnica_data?.user?.address && window.santehnica_data.user.address !== ''
          ? window.santehnica_data.user.address
          : cookieData?.address ?? '',
    })
  },
)

userData.subscribe((currentUserData) => {
  if (
    currentUserData.name !== '' ||
    currentUserData.email !== '' ||
    currentUserData.phone !== '' ||
    currentUserData.phone2 !== '' ||
    currentUserData.address !== ''
  ) {
    Cookies.set(
      'userData',
      JSON.stringify({
        name: currentUserData.name,
        email: currentUserData.email,
        phone: currentUserData.phone,
        phone2: currentUserData.phone2,
        address: currentUserData.address,
      }),
      { expires: 2, secure: true, sameSite: 'strict' },
    )
  }
})

type UserDataCookie = Omit<UserData, 'id'>

export type UserData = {
  id: string
  name: string
  phone: string
  phone2: string
  email: string
  address: string
}
