import { captureException } from '@sentry/svelte'
import Cookies from 'js-cookie'

export const initCookieWarning = async () => {
  // cookie уже есть
  if (Cookies.get('CookieInfoScript')) {
    return
  }

  try {
    const cookieWarningImport = await import('@js/components/CookieWarning/CookieWarning.svelte')

    void new cookieWarningImport.default({
      target: document.body,
    })
  } catch (error: unknown) {
    captureException(error)
  }
}
