import { type CartData, type CartRequestData } from '@js/api/cart/types.d'
import { notiStore } from '@js/components/Notifications/notification-store.ts'
import { cartData } from '@js/stores/cartData.ts'
import { cartUpdating } from '@js/stores/globalStores.ts'
import { captureException } from '@sentry/svelte'
import wretch from 'wretch'
import { retry } from 'wretch/middlewares'

const processCartData = (data: CartRequestData) => {
  const output: CartData = {
    ...data,
    cart: {
      ...data.cart,
      purchases: new Map(data.cart?.purchases ? Object.entries(data.cart.purchases) : []),
    },
  }

  return output
}

export const makeCartRequest = async (
  parameters_: Record<string, string>,
  errorMessage: string | null = 'Не удалось обновить корзину',
) => {
  const parameters = new URLSearchParams(parameters_)

  cartUpdating.set(true)

  let localWretch = wretch('/cart/?' + parameters.toString()).middlewares([
    retry({
      maxAttempts: 3,
    }),
  ])

  if (import.meta.env.DEV) {
    // eslint-disable-next-line no-console
    console.debug('Параметры запроса:', parameters.toString())

    const { delay } = await import('wretch/middlewares')

    localWretch = localWretch.url('/js/__development-api/cart.json', true).middlewares([delay(1_000)])
  }

  const data = await localWretch
    .get()
    .json<CartRequestData>()
    .catch((error) => {
      captureException(error, error.response ?? null)
      if (errorMessage) {
        notiStore.push('error', { timeout: false, props: { content: errorMessage } })
      }

      return undefined
    })

  if (data) {
    cartData.set(processCartData(data))
  }

  cartUpdating.set(false)

  return Boolean(data)
}
