import { type CartData, type CartProduct } from '@js/api/cart/types.d'
import { cartData } from '@js/stores/cartData.ts'
import { captureException } from '@sentry/svelte'
import { destr } from 'destr'
import { get } from 'svelte/store'

export const convertCartDataToEcommerceData = (
  localCartData: CartData | undefined = get(cartData),
  event: EcommerceEvent = 'checkout',
  productID: string | undefined = undefined,
  amount: string | number = 1,
): EcommerceData | null => {
  if (!localCartData) {
    return null
  }

  let cartProduct: CartProduct | undefined
  if (productID) {
    cartProduct = Array.from(localCartData.cart.purchases.values()).find((item) => item.id === productID)
  }

  if (event === 'add-to-cart' && cartProduct) {
    return {
      event,
      currencyCode: localCartData.currency_code,
      purchases: [
        {
          id: cartProduct.product_id,
          variant_id: cartProduct.id,
          name: cartProduct.name,
          price: cartProduct.price,
          amount,
        },
      ],
    }
  } else if (event === 'remove-from-cart' && cartProduct) {
    return {
      event,
      currencyCode: localCartData.currency_code,
      purchases: [
        {
          id: cartProduct.product_id,
          variant_id: cartProduct.id,
          name: cartProduct.name,
          price: cartProduct.price,
          amount: cartProduct.amount,
        },
      ],
    }
  } else if (event === 'checkout') {
    return {
      event: 'checkout',
      currencyCode: localCartData.currency_code,
      total_price: localCartData.cart.total_price,
      purchases: Array.from(localCartData.cart.purchases.values()).map((item) => {
        return {
          id: item.product_id,
          variant_id: item.id,
          name: item.name,
          price: item.price,
          amount: item.amount,
        }
      }),
    }
  }

  return null
}

export const sendEcommerceDataOnAddToCart = (productID: string, amount: string | number = 1) => {
  const localCartData = get(cartData)

  if (!localCartData) {
    return
  }

  const ecommerceData = convertCartDataToEcommerceData(localCartData, 'add-to-cart', productID, amount)
  if (ecommerceData) {
    sendEcommerceData(ecommerceData)
  }
}

export const sendEcommerceDataOnRemoveFromCart = (cartItemID: number | string) => {
  const localCartData = get(cartData)

  if (!localCartData) {
    return
  }

  const product = localCartData.cart.purchases.get(String(cartItemID))
  if (product) {
    const ecommerceData = convertCartDataToEcommerceData(localCartData, 'remove-from-cart', product.id)
    if (ecommerceData) {
      sendEcommerceData(ecommerceData)
    }
  }
}

// eslint-disable-next-line func-style
export function sendEcommerceData(ecommerceData: EcommerceData) {
  if (ecommerceData.order && window.ym) {
    window.ym(40_771, 'reachGoal', ecommerceData.order.is1click ? 'OrderFast' : 'Order')
  }

  const productsArray = ecommerceData.purchases.map((purchase) => {
    return {
      sku: purchase.sku ?? '',
      name: purchase.name,
      id: purchase.id,
      price: purchase.price,
      brand: purchase.brand ?? '',
      category: purchase.category ?? '',
      variant: purchase.variant ?? '',
      quantity: purchase.amount,
    }
  })

  const productsVariantIDs = ecommerceData.purchases.map((purchase) => purchase.variant_id)

  let eventData = {}
  let goods_id: string | string[] = productsVariantIDs
  let goods_price =
    ecommerceData.event === 'order' && ecommerceData.order
      ? ecommerceData.order.total_price
      : ecommerceData.total_price ?? ''
  let page_type = ecommerceData.event === 'order' ? 'purchase' : 'cart'
  let pixelMgEventAction = 'Purchase'
  let pixelMgEventNonInteraction = 'False'

  if (ecommerceData.event === 'order' && ecommerceData.order) {
    eventData = {
      purchase: {
        actionField: {
          id: ecommerceData.order.id, // Номер заказа
          revenue: ecommerceData.order.total_price, // Сумма заказа
          goal_id: ecommerceData.order.is1click ? 32_609_148 : 325_068_281,
        },
        products: productsArray,
      },
    }
  } else if (ecommerceData.event === 'checkout') {
    eventData = {
      checkout: {
        actionField: {
          step: 1,
        },
        products: productsArray,
      },
    }
    pixelMgEventAction = 'Checkout Step 1'
  } else if (ecommerceData.event === 'add-to-cart') {
    eventData = {
      add: {
        products: productsArray,
      },
    }
    goods_id = ecommerceData.purchases[0].id
    goods_price = ecommerceData.purchases[0].price
    page_type = 'add_product'
    pixelMgEventAction = 'Adding a Product to a Shopping Cart'
  } else if (ecommerceData.event === 'remove-from-cart') {
    eventData = {
      remove: {
        products: productsArray,
      },
    }
    goods_id = ecommerceData.purchases[0].id
    goods_price = ecommerceData.purchases[0].price
    page_type = 'remove_product'
    pixelMgEventAction = 'Removing a Product from a Shopping Cart'
  } else if (ecommerceData.event === 'product-page-open') {
    eventData = {
      detail: {
        products: productsArray,
      },
    }
    goods_id = ecommerceData.purchases[0].id
    goods_price = ecommerceData.purchases[0].price
    page_type = 'product'
    pixelMgEventAction = 'Product Details'
    pixelMgEventNonInteraction = 'True'
  }

  window.dataLayer?.push({
    ecommerce: {
      currencyCode: ecommerceData.currencyCode ?? 'RUB',
      ...eventData,
    },

    goods_id,
    goods_price,
    page_type,
    event: 'pixel-mg-event',
    'pixel-mg-event-category': 'Enhanced Ecommerce',
    'pixel-mg-event-action': pixelMgEventAction,
    'pixel-mg-event-non-interaction': pixelMgEventNonInteraction,
  })
}

const sendGtagData = (ecommerceData: EcommerceData) => {
  if (window.gtag && ecommerceData.event === 'order' && ecommerceData.order) {
    const items = ecommerceData.purchases.map((purchase) => {
      const price = typeof purchase.price === 'string' ? Number.parseFloat(purchase.price) : purchase.price
      const price_old =
        typeof purchase.price_old === 'string' ? Number.parseFloat(purchase.price_old) : purchase.price_old ?? 0
      return {
        item_id: purchase.id, // id товара
        item_name: purchase.name, // Название товара
        discount: price_old < 0.1 ? 0 : price_old - price, // Абсолютное значение скидки на товар. Товар стоил 100р. Со скидкой стоит 80р. Скидка — 20р.
        price: purchase.price, // Цена товара. С учётом скидки или без?
        quantity: typeof purchase.amount === 'string' ? Number.parseInt(purchase.amount, 10) : 'number', // Кол-во товара в заказе
      }
    })

    window.gtag('event', 'purchase', {
      transaction_id: ecommerceData.order.id, // Номер заказа
      value: Number.parseFloat(ecommerceData.order.total_price), // Сумма заказа без доставки
      shipping: Number.parseFloat(ecommerceData.order.delivery_price), // Стоимость доставки
      currency: ecommerceData.order.currency, // RUB
      items,
    })
  }
}

export const getEcommerceDataFromPage = () => {
  const ecommerceDataElement = document.querySelector<HTMLElement>('#ecommerce-data')

  if (!ecommerceDataElement) {
    return
  }

  try {
    const ecommerceData = destr<EcommerceData>(ecommerceDataElement.innerHTML)
    sendEcommerceData(ecommerceData)
    sendGtagData(ecommerceData)
  } catch (error) {
    captureException(error)
  }
}

type EcommerceData = {
  event: EcommerceEvent
  currencyCode?: string
  total_price?: string | number
  order?: {
    id: string
    total_price: string
    delivery_price: string
    currency: string
    exchange_rate: number | string
    is1click: boolean
  }
  purchases: EcommerceDataPurchase[]
}

type EcommerceEvent = 'order' | 'checkout' | 'add-to-cart' | 'remove-from-cart' | 'product-page-open'

type EcommerceDataPurchase = {
  sku?: string
  id: string
  variant_id: string
  name: string
  brand?: string
  category?: string
  variant?: string
  price: string | number
  price_old?: string | number
  amount: string | number
}
