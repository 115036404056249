<script lang="ts">
  let className = ''
  export { className as class }

  export let size: string | number = 30
</script>

<img
  class={className}
  width={size}
  height={size}
  src="{import.meta.env.VITE_TEMPLATE_DIR}media/loader.svg"
  alt="Загрузка…"
/>
