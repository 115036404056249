// TODO: проверить работу
export const initYandexMetrika = () => {
  if (typeof ym === 'undefined') {
    return
  }

  // Элементы с data-ym отправляют ReachGoal в Метрику
  const dataYmElements = document.querySelectorAll<HTMLLinkElement | HTMLButtonElement>('[data-ym]')
  for (const element of dataYmElements) {
    // eslint-disable-next-line @typescript-eslint/no-loop-func
    element.addEventListener('click', () => {
      if (element.dataset.ym) {
        ym<'40771'>(import.meta.env.VITE_YANDEX_METRIKA_ID, 'reachGoal', element.dataset.ym)
      }
    })
  }
}
