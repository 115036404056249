// На разных разрешениях надо отображать разное количество, поэтому JS...
import { type CardCollectionItem } from '@js/components/product-card/card-collection-items/types.ts'

const getCardCollectionItems = (collectionItemsWrap: HTMLElement) => {
  const collectionItems = collectionItemsWrap.querySelectorAll<HTMLElement>('.card-collection-items__item')
  const processedCollectionItems: CardCollectionItem[] = []

  for (const item of collectionItems) {
    const id = item.dataset.id
    const name = item.querySelector<HTMLElement>('.card-collection-items__link')?.getAttribute('title')
    const image = item.querySelector<HTMLElement>('.card-collection-items__image')?.getAttribute('src')
    if (id && name && image) {
      processedCollectionItems.push({
        id,
        name,
        image,
      })
    }
  }

  return processedCollectionItems
}

export const initCardCollectionItems = async (card: HTMLElement) => {
  const collectionItemsWrap = card.querySelector<HTMLElement>('.card-collection-items')

  if (!collectionItemsWrap) {
    return
  }

  const items = getCardCollectionItems(collectionItemsWrap)

  if (items.length > 0) {
    const { default: CardCollectionItems } = await import('./CardCollectionItems.svelte')
    collectionItemsWrap.innerHTML = ''
    void new CardCollectionItems({
      target: collectionItemsWrap,
      props: {
        cardCollectionItems: items,
        collectionItemsWrap,
        card,
        mode: collectionItemsWrap.dataset.mode === 'static' ? 'static' : 'default',
      },
    })
  }
}

export const initAllCardsCollectionItems = (parent: HTMLElement | Document = document) => {
  const productCards = parent.querySelectorAll<HTMLElement>('.product-card--large')

  for (const card of productCards) {
    initCardCollectionItems(card)
  }
}
