import { touchHandler } from '@js/includes/touchHandler'
import { mobile } from '@js/stores/globalStores'
import { Modal } from 'bootstrap'
import { get } from 'svelte/store'

const allModals = new Set<HTMLElement>()
const initializedModals = new WeakSet<HTMLElement>()

const cleanupDrag = (modal: HTMLElement) => {
  modal.style.top = '0'
  modal.style.left = '0'
  modal.classList.remove('dragging', 'dragging--y')
}

const initDraggable = () => {
  for (const modal of allModals) {
    if (initializedModals.has(modal)) {
      continue
    }

    initializedModals.add(modal)

    const modalInstance = Modal.getOrCreateInstance(modal)

    touchHandler({
      element: modal,
      startHandler: (_dragX, _dragY, event) => {
        if (!get(mobile)) {
          return
        }

        const currentTarget = event?.target as HTMLElement
        const targetModalBody = currentTarget.closest<HTMLElement>('.modal-body')

        // Не дрэгать внутри карты
        if (currentTarget.matches('ymaps') || currentTarget.closest('ymaps')) {
          return
        }

        modal.classList.add('dragging')

        // Сколлящийся элемент
        if (targetModalBody) {
          const hasOverflowY = targetModalBody.scrollHeight > targetModalBody.offsetHeight
          if (!hasOverflowY || (hasOverflowY && targetModalBody.scrollTop < 10)) {
            // Применяем дрэг только если скролл меньше 10px (iOS, привет)
            modal.classList.add('dragging--y')
          }
        } else {
          modal.classList.add('dragging--y')
        }
      },
      dragHandler: (_dragX, dragY) => {
        // Только при наличии класса dragging
        if (modal.classList.contains('dragging--y') && dragY && dragY > 0) {
          modal.style.top = `${dragY}px`
        }
      },
      endHandler: (_dragX, dragY) => {
        // закрывать вертикальным дрэгом
        if (modal.classList.contains('dragging--y') && dragY && dragY > 100) {
          modal.style.top = '110vh'
          modalInstance.hide()
          setTimeout(() => {
            cleanupDrag(modal)
          }, 300)
        } else {
          cleanupDrag(modal)
        }
      },
    })
  }
}

export const addModalDrag = (modal: HTMLElement) => {
  allModals.add(modal)
  initDraggable()
}

export const removeModalDrag = (modal: HTMLElement) => {
  allModals.delete(modal)
  initializedModals.delete(modal)
  touchHandler({
    element: modal,
    mode: 'remove',
  })
}

export const initStaticDraggableModals = () => {
  const staticModals = document.querySelectorAll<HTMLElement>('.modal')

  for (const modal of staticModals) {
    addModalDrag(modal)
  }

  initDraggable()
}
