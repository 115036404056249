// Меняет текст в кнопке коллапса при изменении состояния
export const initBootstrapCollapseTextHandler = () => {
  const collapseButtons = document.querySelectorAll<HTMLElement>('.btn[data-bs-toggle="collapse"][data-bs-shown-text]')

  for (const button of collapseButtons) {
    const baseText = button.dataset.bsHiddenText ?? button.textContent
    const shownText = button.dataset.bsShownText
    const collapseTarget = button.dataset.bsTarget ? document.querySelector<HTMLElement>(button.dataset.bsTarget) : null
    if (baseText && shownText && collapseTarget) {
      collapseTarget.addEventListener('hide.bs.collapse', () => {
        button.innerHTML = baseText
      })

      collapseTarget.addEventListener('show.bs.collapse', () => {
        button.innerHTML = shownText
      })
    }
  }
}
