import { notiStore } from '@js/components/Notifications/notification-store.ts'
import { userData } from '@js/stores/userData.ts'
import { get } from 'svelte/store'
import wretch from 'wretch'
// eslint-disable-next-line import/no-named-as-default
import FormUrlAddon from 'wretch/addons/formUrl'
import { retry } from 'wretch/middlewares'

// Возвращает true в случае успеха. На данный момент большая часть логики на бэке.
export const addProductNote = async (productID: string, note: string, nodeID?: string) => {
  const localWretch = wretch('/ajax/get_data.php')
    .addon(FormUrlAddon)
    .middlewares([
      retry({
        maxAttempts: 3,
      }),
    ])
  let response: AddNoteResponse
  if (import.meta.env.DEV) {
    response = { success: true }
  } else {
    response = await localWretch
      .formUrl({
        object: 'products',
        mode: 'product-note-add',
        pid: productID,
        information: note,
        session_id: window.santehnica_data?.session ?? '',
        user_id: get(userData).id,
        note_id: nodeID ?? '',
      } satisfies AddNoteRequest)
      .post()
      .json<AddNoteResponse>()
      .catch(() => {
        return { success: false } as AddNoteResponse
      })
  }

  if (!response.success) {
    notiStore.push('error', { timeout: false, props: { content: 'Не удалось добавить заметку' } })
    return false
  }

  return true
}

// Возвращает true в случае успеха.
export const saveProductNote = async (productID: string, note: string) => {
  const localWretch = wretch('/ajax/get_data.php')
    .addon(FormUrlAddon)
    .middlewares([
      retry({
        maxAttempts: 3,
      }),
    ])
  let response: AddNoteResponse
  if (import.meta.env.DEV) {
    response = { success: true }
  } else {
    const currentUserData = get(userData)
    response = await localWretch
      .formUrl({
        object: 'notes',
        mode: 'save_note',
        product_id: productID,
        text: note,
        session_id: window.santehnica_data?.session ?? '',
        uid: currentUserData.id,
      })
      .post()
      .json<AddNoteResponse>()
      .catch(() => {
        return { success: false } as AddNoteResponse
      })
  }

  if (!response.success) {
    notiStore.push('error', { timeout: false, props: { content: 'Не удалось изменить заметку' } })
    return false
  }

  return true
}

export type AddNoteRequest = {
  object: 'products'
  mode: 'product-note-add'
  pid: string
  information: string
  session_id: string
  user_id: string
  note_id: string
}

export type AddNoteResponse = {
  success: boolean
}
