import { mobile } from '@js/stores/globalStores.ts'
import { get } from 'svelte/store'

export const mobileDesktopExecutor: MobileDesktopExecutor = (options) => {
  let previousState = get(mobile)
  let unsubscriber: (() => void) | undefined

  mobile.subscribe((isMobile) => {
    if (isMobile !== previousState) {
      if (unsubscriber) {
        unsubscriber()
      }

      previousState = isMobile
    }

    if (isMobile && options.onMobile) {
      unsubscriber = options.onMobile() as (() => void) | undefined
    } else if (options.onDesktop) {
      unsubscriber = options.onDesktop() as (() => void) | undefined
    }
  })
}

export type MobileDesktopExecutor = (options: {
  onMobile?: MobileDesktopExecutorFunction
  onDesktop?: MobileDesktopExecutorFunction
}) => undefined

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export type MobileDesktopExecutorFunction = () => void | (() => void)
