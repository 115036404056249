import { cartData } from '@js/stores/cartData.ts'
import { favouritesStore } from '@js/stores/favouritesStore.ts'
import { mobile } from '@js/stores/globalStores.ts'

export const initMobileFooter = () => {
  const mobileFooter = document.querySelector('.mobile-footer')

  if (!mobileFooter) {
    return
  }

  mobile.subscribe((isMobile) => {
    if (isMobile) {
      document.documentElement.style.setProperty('--uiui-bottom-bar-height', '60px')
    } else {
      document.documentElement.style.setProperty('--uiui-bottom-bar-height', '0px')
    }
  })

  const footerCart = mobileFooter.querySelector('.mobile-footer__cart')

  if (footerCart) {
    const countBadge = document.createElement('span')
    countBadge.classList.add('count')

    cartData.subscribe((currentCartData) => {
      if (currentCartData && currentCartData.cart.total_products > 0) {
        countBadge.innerHTML = String(currentCartData.cart.total_products)
        countBadge.classList.remove('d-none')
      } else {
        countBadge.classList.add('d-none')
      }
    })

    footerCart.appendChild(countBadge)
  }

  const footerFavorites = mobileFooter.querySelector('.mobile-footer__favorites')
  if (footerFavorites) {
    const favoritesCountBadge = document.createElement('span')
    favoritesCountBadge.classList.add('count')

    favouritesStore.subscribe((currentFavorites) => {
      if (currentFavorites.size > 0) {
        favoritesCountBadge.innerHTML = String(currentFavorites.size)
        favoritesCountBadge.classList.remove('d-none')
      } else {
        favoritesCountBadge.classList.add('d-none')
      }
    })

    footerFavorites.appendChild(favoritesCountBadge)
  }
}
