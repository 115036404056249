import { saveCardNeighbors } from '@js/components/product-card/cardNeighbors.ts'
import { defaultSwiperSettings } from '@js/components/Swiper/defaultSwiperSettings.ts'
import { mobile } from '@js/stores/globalStores.ts'
import { get } from 'svelte/store'
import { Swiper } from 'swiper'

const allCardSwipersWraps = new Set<HTMLElement>()
const allCardSwipers = new Set<Swiper>()
const allObservers = new Set<IntersectionObserver>()
let subscribedToMobileChanges = false

const paginationHoverAction = (currentSwiper: HTMLElement, swiper: Swiper) => {
  const paginationBullets = currentSwiper.querySelectorAll<HTMLElement>('.swiper-pagination-bullet')
  for (const thisBullet of paginationBullets) {
    // switch slide on click if not active
    thisBullet.addEventListener('click', (event) => {
      if (!thisBullet.classList.contains('swiper-pagination-bullet-active')) {
        event.preventDefault()
        swiper.slideTo(Number(thisBullet.dataset.index))
      }
    })

    // switch slide on hover
    thisBullet.addEventListener('mouseenter', () => {
      // Hover intent
      setTimeout(() => {
        if (thisBullet.dataset.index && thisBullet.matches(':hover')) {
          swiper.slideTo(Number(thisBullet.dataset.index))
        }
      }, 50)
    })
  }

  // swiper.el.addEventListener(
  //   'mouseleave',
  //   () => {
  //     swiper.slideTo(0)
  //   },
  //   { passive: true },
  // )
}

export const initProductCardGallerySwiper = (currentSwiper: HTMLElement) => {
  const productCard = currentSwiper.closest<HTMLElement>('.product-card')
  const productURL = productCard?.dataset.url

  if (!productCard) {
    return null
  }

  // init product card gallery Swiper:
  const cardSwiper = new Swiper(currentSwiper, {
    ...defaultSwiperSettings,
    init: false,
    autoplay: false,
    nested: true,
    speed: 100,
    pagination: {
      el: '.swiper-pagination',
      renderBullet(index, className) {
        return `<a href="${productURL}" class="${className}" data-index="${index}"></a>`
      },
    },
    slideClass: 'swiper-slide--nested',
    on: {
      init(swiper) {
        paginationHoverAction(currentSwiper, swiper)
      },
      click() {
        if (productCard.dataset.url) {
          saveCardNeighbors(productCard)
          window.location.href = productCard.dataset.url
        }
      },
      touchStart(swiper) {
        swiper.el.classList.add('is-touching')
        swiper.el.classList.add('was-touched')

        // Удаляем was-touched у других слайдеров, мде…
        const otherSwipers = document.querySelectorAll('.swiper-wrap--product-card-gallery .swiper.was-touched')
        for (const otherSwiper of otherSwipers) {
          if (otherSwiper !== swiper.el) {
            otherSwiper.classList.remove('was-touched')
          }
        }
      },
      touchEnd(swiper) {
        swiper.el.classList.remove('is-touching')
      },
    },
    breakpoints: {
      992: {
        speed: 0,
      },
    },
  })

  allCardSwipers.add(cardSwiper)

  const observer = new IntersectionObserver((entries) => {
    const firstEntry = entries[0]
    if (firstEntry.isIntersecting) {
      cardSwiper.init()
      observer.disconnect()
    }
  })

  observer.observe(currentSwiper)
  allObservers.add(observer)

  return cardSwiper
}

const isInsideSlider = (element: HTMLElement | null) => {
  return element?.closest('.swiper')
}

export const initProductCardGallerySwipersMobileSubscription = () => {
  mobile.subscribe((isMobile) => {
    if (allObservers.size > 0) {
      for (const observer of allObservers) {
        observer.disconnect()
      }

      allObservers.clear()
    }

    if (allCardSwipers.size > 0) {
      for (const cardSwiper of allCardSwipers) {
        try {
          cardSwiper.destroy(true, true)
        } catch {
          /* Похоже, баг в Swiper */
        }
      }

      for (const cardSwiperWrap of allCardSwipersWraps) {
        if (!document.contains(cardSwiperWrap)) {
          allCardSwipersWraps.delete(cardSwiperWrap)
          continue
        }

        if (!isMobile || (isMobile && !isInsideSlider(cardSwiperWrap.parentElement))) {
          initProductCardGallerySwiper(cardSwiperWrap)
        }
      }
    }
  })
}

export const addProductCardGallerySwipers = (parent: Document | HTMLElement) => {
  const cardGallerySwipers = parent.querySelectorAll<HTMLElement>('.swiper-wrap--product-card-gallery .swiper')
  const isMobile = get(mobile)

  if (!subscribedToMobileChanges) {
    initProductCardGallerySwipersMobileSubscription()
    subscribedToMobileChanges = true
  }

  for (const cardGalleryWrap of cardGallerySwipers) {
    if (!isMobile || (isMobile && !isInsideSlider(cardGalleryWrap.parentElement))) {
      initProductCardGallerySwiper(cardGalleryWrap)
      allCardSwipersWraps.add(cardGalleryWrap)
    }
  }
}
