import { shouldPolyfill } from '@formatjs/intl-numberformat/should-polyfill'

export const numberFormatPolyfill = async () => {
  let locale = 'ru'

  switch (window.santehnica_data?.cityID) {
    case 7:
      locale = 'ru-KZ'
      break
    case 196:
      locale = 'ru-KG'
      break
    default:
      break
  }

  let supportsNarrowSymbol = true

  try {
    new Intl.NumberFormat('ru', {
      style: 'currency',
      currency: 'RUB',
      currencyDisplay: 'narrowSymbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(1_000)
  } catch {
    supportsNarrowSymbol = false
  }

  const unsupportedLocale = shouldPolyfill(locale)
  // This locale is supported
  if (supportsNarrowSymbol && !unsupportedLocale) {
    return
  }

  // Load the polyfill 1st BEFORE loading data
  await import('@formatjs/intl-numberformat/polyfill-force')

  switch (locale) {
    case 'ru-KZ':
      await import(`@formatjs/intl-numberformat/locale-data/ru-KZ.js`)
      break
    case 'ru-KG':
      await import(`@formatjs/intl-numberformat/locale-data/ru-KG.js`)
      break
    default:
      await import(`@formatjs/intl-numberformat/locale-data/ru.js`)
      break
  }
}
