import { saveProductNote } from '@js/api/addProductNote.ts'

export const initEditableNote = (card: HTMLElement) => {
  const noteElement = card.querySelector<HTMLElement>('.product-card-notes__user-note-text')
  const productID = card.dataset.productId

  if (!noteElement || !productID) {
    return undefined
  }

  let previousText = noteElement.textContent ?? ''

  const onNoteSave = async () => {
    const newText = noteElement.textContent
    if (newText !== null && newText !== previousText) {
      const success = await saveProductNote(productID, newText)
      if (success) {
        // eslint-disable-next-line require-atomic-updates
        previousText = newText
      }
    }
  }

  noteElement.addEventListener('blur', onNoteSave)

  noteElement.contentEditable = 'true'
  noteElement.setAttribute('role', 'textbox')
  noteElement.setAttribute('aria-multiline', 'true')
  noteElement.setAttribute('aria-label', 'Редактируемый текст заметки')

  return () => {
    noteElement.removeEventListener('blur', onNoteSave)

    noteElement.contentEditable = 'false'
    noteElement.removeAttribute('role')
    noteElement.removeAttribute('aria-multiline')
    noteElement.removeAttribute('aria-label')
  }
}
