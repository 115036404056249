import type AddedToCart from '@js/components/modals/added-to-cart/AddedToCart.svelte'
import type { ComponentProps } from 'svelte'

let addedToCartModal: AddedToCart | undefined

export const showAddToCartModal = async (props: ComponentProps<AddedToCart>) => {
  const { default: AddedToCart } = await import('@js/components/modals/added-to-cart/AddedToCart.svelte')
  if (!addedToCartModal) {
    addedToCartModal = new AddedToCart({
      target: document.body,
    })
  }

  addedToCartModal.$set({
    ...props,
    show: true,
    productID: import.meta.env.DEV ? '987271' : props.productID,
    optionIDs: import.meta.env.DEV ? new Set(['255321']) : props.optionIDs ?? new Set(),
  })
}
