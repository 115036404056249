// eslint-disable-next-line import/no-unassigned-import
import 'large-small-dynamic-viewport-units-polyfill'
import { localePolyfill } from '@js/includes/polyfills/localePolyfill.ts'
import { numberFormatPolyfill } from '@js/includes/polyfills/numberFormatPolyfill.ts'
import { pluralPolyfill } from '@js/includes/polyfills/pluralPolyfill.ts'

export const loadPolyfills = async () => {
  await localePolyfill()
  await pluralPolyfill()
  await numberFormatPolyfill()

  if (!('ResizeObserver' in window)) {
    // Loads polyfill asynchronously, only if required.
    const module = await import('@juggle/resize-observer')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    // eslint-disable-next-line require-atomic-updates
    window.ResizeObserver = module.ResizeObserver
  }

  if (
    !(
      'IntersectionObserver' in window &&
      'IntersectionObserverEntry' in window &&
      'intersectionRatio' in window.IntersectionObserverEntry.prototype
    )
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    await import('intersection-observer')
  }
}
