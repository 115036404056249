import { debounce } from '@js/includes/functions.ts'

const triggerResize = (targetMap: Map<HTMLElement, Range>) => {
  for (const [element, range] of targetMap) {
    const clientRect = range.getBoundingClientRect()
    element.style.width = clientRect.width + 'px'
  }
}

export const initForcedTextWidthHelper = (
  targetElements = document.querySelectorAll<HTMLElement>('.force-text-width'),
) => {
  const targetMap = new Map<HTMLElement, Range>()

  for (const target of targetElements) {
    const targetElementRange = document.createRange()
    targetElementRange.setStartBefore(target.childNodes[0])
    targetElementRange.setEndAfter(target.childNodes[0])
    targetMap.set(target, targetElementRange)
  }

  document.fonts.onloadingdone = () => {
    triggerResize(targetMap)
  }

  window.addEventListener(
    'resize',
    debounce(() => {
      triggerResize(targetMap)
    }),
  )

  triggerResize(targetMap)
}
