import { readable, writable } from 'svelte/store'

export const catalogMenuOpen = writable(false)
export const mobile = writable(false, (set) => {
  const mobileQuery = window.matchMedia('screen and (max-width: 991.9px)')
  set(mobileQuery.matches)
})
export const cartUpdating = writable(false)
export const siteSection = readable<SiteSection>('default', (set) => {
  const substrateElement = document.querySelector<HTMLElement>('.page-substrate')
  if (substrateElement?.classList.contains('page-substrate--category-plitka')) {
    set('plitka')
  } else if (substrateElement?.classList.contains('page-substrate--category-napolnye-pokrytiya')) {
    set('napolnye-pokrytiya')
  }
})

export type SiteSection = 'default' | 'plitka' | 'napolnye-pokrytiya'
