import { initRegisterForm } from '@js/components/initRegisterForm'

export const initLoginModal = () => {
  const loginModal = document.querySelector<HTMLElement>('#modal-login')
  if (!loginModal) {
    return
  }

  loginModal.addEventListener(
    'show.bs.modal',
    async () => {
      await initRegisterForm(loginModal)
    },
    { once: true },
  )
}
