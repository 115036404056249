import { shouldPolyfill } from '@formatjs/intl-pluralrules/should-polyfill'

export const pluralPolyfill = async () => {
  const unsupportedLocale = shouldPolyfill('ru')
  // This locale is supported
  if (!unsupportedLocale) {
    return
  }

  // Load the polyfill 1st BEFORE loading data
  await import('@formatjs/intl-pluralrules/polyfill-force.js')
  await import('@formatjs/intl-pluralrules/locale-data/ru.js')
}
