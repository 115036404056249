let navigationElements: HTMLElement[] = []

const onKeyboardEvent = (event: KeyboardEvent) => {
  const currentFocusedElement = document.activeElement as HTMLElement | null
  const currentFocusedNavigationElementIndex = currentFocusedElement
    ? navigationElements.indexOf(currentFocusedElement)
    : 0
  let focusElement: HTMLElement | null = null
  switch (event.key) {
    case 'ArrowDown':
      if (navigationElements.length > currentFocusedNavigationElementIndex + 1) {
        focusElement = navigationElements[currentFocusedNavigationElementIndex + 1]
      } else {
        focusElement = navigationElements[0]
      }

      break
    case 'ArrowUp':
      if (currentFocusedNavigationElementIndex > 0) {
        focusElement = navigationElements[currentFocusedNavigationElementIndex - 1]
      } else {
        focusElement = navigationElements[navigationElements.length - 1]
      }

      break
  }

  if (focusElement) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    focusElement.focus({ focusVisible: false }) // Свойство только для Firefox
  }
}

export const initHeaderSearchArrowNav = (searchContainer: HTMLElement) => {
  navigationElements = Array.from(
    searchContainer.querySelectorAll<HTMLElement>('input#search-main, a.search-results-modal-result__name'),
  )
  searchContainer.addEventListener('keyup', onKeyboardEvent, { passive: true })
}

export const disableHeaderSearchArrowNav = (searchContainer: HTMLElement) => {
  searchContainer.removeEventListener('keyup', onKeyboardEvent)
  navigationElements = []
}
