import { type CartData } from '@js/api/cart/types.d'
import { derived, type Stores, writable } from 'svelte/store'

export const cartData = writable<CartData | undefined>()

export const cartPurchaseIDs = derived<Stores, Set<string>>(cartData, (data: CartData | undefined) => {
  const localCartPurchaseIDs = new Set<string>()

  if (data && data.cart.purchases.size > 0) {
    for (const currentPurchase of data.cart.purchases.values()) {
      localCartPurchaseIDs.add(currentPurchase.id)
    }
  }

  return localCartPurchaseIDs
})
